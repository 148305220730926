import React from "react";

import "./App.css";
import Home from "./screen/home";
// import ImageScreen from './screen/imageScreen';

function App() {
  return (
    <>
      <Home />

      {/* <ImageScreen /> */}
    </>
  );
}

export default App;
